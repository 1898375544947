exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-moving-responsibly-js": () => import("./../../../src/pages/moving-responsibly.js" /* webpackChunkName: "component---src-pages-moving-responsibly-js" */),
  "component---src-pages-testing-jsx": () => import("./../../../src/pages/testing.jsx" /* webpackChunkName: "component---src-pages-testing-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-capabiltiies-landing-js": () => import("./../../../src/templates/capabiltiies-landing.js" /* webpackChunkName: "component---src-templates-capabiltiies-landing-js" */),
  "component---src-templates-capabiltiies-single-js": () => import("./../../../src/templates/capabiltiies-single.js" /* webpackChunkName: "component---src-templates-capabiltiies-single-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/news-category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-landing-js": () => import("./../../../src/templates/news-landing.js" /* webpackChunkName: "component---src-templates-news-landing-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-our-project-js": () => import("./../../../src/templates/our-project.js" /* webpackChunkName: "component---src-templates-our-project-js" */),
  "component---src-templates-our-work-js": () => import("./../../../src/templates/our-work.js" /* webpackChunkName: "component---src-templates-our-work-js" */),
  "component---src-templates-quality-safety-standards-js": () => import("./../../../src/templates/quality-safety-standards.js" /* webpackChunkName: "component---src-templates-quality-safety-standards-js" */),
  "component---src-templates-single-career-js": () => import("./../../../src/templates/singleCareer.js" /* webpackChunkName: "component---src-templates-single-career-js" */)
}

